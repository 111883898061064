<template>
  <v-container class="mb-3 mt-3">
    <v-card class="mb-3">
      <v-card-text>
        <h2 class="text-h4 font-weight-bold">
          Contact US
        </h2>
        <v-divider />
      </v-card-text>

      <v-row
        class="contact-page"
        no-gutters
      >
        <v-col
          cols="12"
          md="4"
          lg="3"
        >
          <v-card-text>
            <div class="contact-page__section-title">
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2">
                  mdi-home
                </v-icon>
                {{ $apps.address }}
              </v-card-text>
              <v-divider class="mb-2 mt-1" />
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2">
                  mdi-phone
                </v-icon>
                {{ $apps.phone }}
              </v-card-text>
              <v-divider class="mb-2 mt-1" />
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2">
                  mdi-earth
                </v-icon>
                {{ $apps.web }}
              </v-card-text>
              <v-divider class="mb-2 mt-1" />
              <v-card-text class="pt-1 pb-1 pl-0">
                <v-icon class="mr-2">
                  mdi-email
                </v-icon>
                {{ $apps.email }}
              </v-card-text>
            </div>
          </v-card-text>
          <v-card-text>
            <div class="google-map">
              <GmapMap
                :center="mapCenter"
                :zoom="11"
                style="width: 100%; height: 500px"
              />
            </div>
          </v-card-text>
        </v-col>

        <v-col
          cols="12"
          md="8"
          lg="9"
        >
          <v-card-text>
            <div class="contact-page__contact-form">
              <v-form
                ref="form"
                v-model="valid"
              >
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="formFields.name"
                        :rules="validationRules.name"
                        :error-messages="errors.name"
                        label="Name *"
                        required
                        outlined
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="formFields.email"
                        :rules="validationRules.email"
                        :error-messages="errors.email"
                        label="Email *"
                        required
                        outlined
                      />
                    </v-col>

                    <v-col cols="12">
                      <v-text-field
                        v-model.trim="formFields.subject"
                        label="Subject *"
                        :rules="validationRules.subject"
                        :error-messages="errors.subject"
                        required
                        outlined
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model.trim="formFields.message"
                        label="Message *"
                        :rules="validationRules.message"
                        :error-messages="errors.message"
                        outlined
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-spacer />
                      <v-btn
                        :disabled="submitting"
                        :loading="submitting"
                        class="ma-2 white--text"
                        color="primary"
                        large
                        @click="onSubmit"
                      >
                        Send
                        <v-icon
                          dark
                          right
                        >
                          mdi-send
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>
            </div>
          </v-card-text>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { contactUs } from '@/api/api';
import Toast from '@/utils/Toast';
import { maxRule, minRule, requiredRule, userEmailRule, userNameRule } from '@/utils/Validation/validationRules';
import ErrorHelper from '@/utils/ErrorHelper';

export default {
  name: 'ContactView',
  data: () => ({
    mapCenter: { lat: -27, lng: 153 },
    submitting: false,
    valid: false,
    formFields: {
     name: '',
     email: '',
     subject: '',
     message: '',
   },
    validationRules: {
      name: [
        ...userNameRule(),
        ...minRule(2, 'Name'),
        ...maxRule(100, 'Name'),
      ],
      email: userEmailRule(),
      subject: [
        ...requiredRule('Subject'),
        ...minRule(5, 'Subject'),
        ...maxRule(120, 'Subject'),
      ],
      message: [
      ...requiredRule('Message'),
      ...minRule(10, 'Message'),
      ...maxRule(500, 'Message'),
      ],
    },
    errors: {
      name: '',
      email: '',
      subject: '',
      message: '',
    },
    zoom: 12,
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    address: '59 Bricklane, London, E1 6QL',
  }),
  methods: {
    onSubmit() {
      if (!this.validate()) return;

      const data = this.getFormValues();
      this.sendData(data);
    },

    validate () {
      return this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    getFormValues() {
      const { name, email, message, subject } = this.formFields;
      return { name, email, message, subject };
    },

    async sendData (data) {
      try {
        this.submitting = true;

        const res = await contactUs(data);

        this.reset();

        Toast.info(res.data.message || 'Message sent successfully');

      } catch (e) {
        Toast.error(e.message);

        ErrorHelper.mapServerError(e, (error, ex) => {
          this.errors.name = ex(error.name);
          this.errors.email = ex(error.email);
          this.errors.subject = ex(error.subject);
          this.errors.message = ex(error.message);
       });

      } finally {
        this.submitting = false;
      }
    },
  }

};
</script>
<style lang="scss">
.gmaps-map {
  min-height: 400px;
}
</style>
